<template>
  <div class="Ordinary_I">
    <div class="headNavs">
      <div class="headTilte">普通商品</div>
      <div class="headDetails">说明：普通商品</div>
    </div>
    <div class="capitalNav">
      <div style="display:flex;justify-content: space-between;">
        <div class="NavLi">
          <div class="NavLiName">商品分类：</div>
          <div class="NavLiIpt">
            <el-cascader
              style="width:217px"
              ref="Casc"
              v-model="Row_piii2"
              :options="CateList"
              :change-on-select="true"
              :props="{ value:'id', label:'name'}"
              @change="handleChange2"></el-cascader>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">名称：</div>
          <div class="NavLiIpt">
            <el-input v-model="search.goods_name" placeholder="搜索名称" style="width: 108%;"></el-input>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="全部">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi">
          <div class="NavLiName">商品规格：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.spec_type" placeholder="全部">
              <el-option v-for="item in TypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="NavLi"></div>
        <div class="NavLi"></div>
      </div>
      <div style="margin-top:20px">
        <div class="NavLi">
          <div class="NavLiName">商品状态：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.goods_status" placeholder="全部">
              <el-option v-for="item in Goods_staus" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="NavLiQuery" style="margin-left: 24px;" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
        </div>
      </div>
    </div>

  </div>


  <div class="TablesList">
    <div class="TablesADD">
      <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
      <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
      <div class="TableIpt"> 
        <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入商品简称">
        <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
      </div>
    </div>
    <el-table
      :data="tableData"
      class="table"
      ref="site_id"
      header-cell-class-name="table-header">
      <el-table-column prop="goods_id" label="ID"  align="center" width="50"></el-table-column>
      <el-table-column prop="" label="站点"  align="center"  width="70">
          <template #default="scope">
            <span v-for="(item,key) in scope.row.site" :key="key">{{item}},</span>
          </template>
      </el-table-column>
      <el-table-column prop="goods_short_name" label="商品简称"  align="center"></el-table-column>
      <el-table-column prop="category.name" label="商品分类"  align="center"> </el-table-column>
      <el-table-column prop="wap_image" label="商品图片"  align="center">
        <template #default="scope">
            <el-image class="Toper"
                :src="scope.row.full_images" alt=""
                :preview-src-list="[scope.row.full_images]"
                :initial-index="0"
                :hide-on-click-modal = true
                fit="cover">
            </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="sale_type_text" label="销售类型"  align="center"> </el-table-column>
      <el-table-column prop="goods_type_text" label="商品类型"  align="center"> </el-table-column>
      <el-table-column prop="spec_type_text" label="商品规格"  align="center"> </el-table-column>
      <el-table-column prop="sales_actual" label="实际销量"  align="center"> </el-table-column>
      <el-table-column prop="goods_sort" label="权重" align="center"></el-table-column>
      <el-table-column prop="goods_status_text" label="商品状态"  align="center"> </el-table-column>
      <el-table-column prop="tuijian_discover" label="发现页推荐"  align="center"> 
        <template  #default="scope">
          <span>{{ {10:'顶部推荐',20:'底部推荐',30:'不推荐'}[scope.row.tuijian_discover || 10] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tuijian_status_text" label="推荐状态"  align="center"> </el-table-column>
      <el-table-column prop="hot_status_text" label="热门状态"  align="center"> </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template #default="scope">
          <div class="el_butto" >
            <el-button
                type="text"
                @click="HandleEdit(scope.$index,scope.row)"
            >编辑
            </el-button>
          </div>
          <div class="el_butto rere" >
            <el-button
                type="text"
                class="red"
                @click="HandleDel(scope.$index, scope.row)"
            >删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>

  <div class="show" v-if="ruleForm2">
    <div class="rightPanel-background"/>
    <div class="EditNew" style="width:900px">
      <div class="EditNewTop">
        <div>{{titleMath}}</div>
        <div @click="Close()"><i class="el-icon-close"></i></div>
      </div>
      <div class="headNavs" style="padding:0 26px">
        <el-menu
          :default-active="acndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect2">
          <el-menu-item index="1">商品信息</el-menu-item>
          <el-menu-item index="2">商品详情</el-menu-item>
          <el-menu-item index="3">会员折扣</el-menu-item>
          <el-menu-item index="4">常见问题</el-menu-item>
          <el-menu-item index="5">SEO设置</el-menu-item>
          <el-menu-item index="6">关联商品</el-menu-item>
          <el-menu-item index="7">信息列表</el-menu-item>
        </el-menu>
      </div>
      <el-form :model="ruleFormRef " :rules="rules" ref="ruleFormRef" >
        <div class="EditNewForm" v-show="info_Show == 0">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName mation">一、基础信息</div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.Row_piii">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品分类</div>
            <div class="EditNewFormItemIpt">
              <el-cascader
                ref="Casc"
                style="width:100%;"
                v-model="ruleFormRef.Row.Row_piii"
                :options="CateList"
                :change-on-select="true"
                :props="{ value:'id', label:'name'}"
                @change="handleChange"></el-cascader>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_short_name">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品简称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="ruleFormRef.Row.goods_short_name" placeholder="请输入商品简称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_name">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品全称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="ruleFormRef.Row.goods_name" placeholder="请输入商品全称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="Row.goods_intro">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品简介</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="ruleFormRef.Row.goods_intro" placeholder="请输入商品全称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="Row.label_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品标签 (可多选)</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleFormRef.Row.label_ids" multiple placeholder="请选择">
                <el-option v-for="item in LabelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="Row.site_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleFormRef.Row.site_ids" multiple placeholder="全部">
                <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName mation">二、销售设置</div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="Row.spec_type">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品规格</div>
            <div class="EditNewFormItemIpt" style="width:90%">
              <el-select v-model="ruleFormRef.Row.spec_type" placeholder="请选择商品规格" @change="Sku_Chang" style="width:33%">
                <el-option v-for="(item,key) in StatisticsColumns.spec_type" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <div v-if="Sku == 1 || Sku == 2 || Sku == 3 || Sku == 4 " style="width:100%">
            <div class="Sku_ADD" style="margin-bottom:25px" v-if="Sku != 4 ">
              <el-button @click="Sku_Chang('add')" type="primary">添加规格</el-button>
            </div>
            <div class="Sku_boder"  v-if="Sku == 2 || Sku == 3 || Sku == 4 ">
              <div v-if="Sku == 3">
                <div  v-for="(item, index) in Spec_many_title" :key="index">
                  <div class="Rig_D ">
                    <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格名</div>
                    <div class="Sku_name" @click="imgClick(index)">
                      <span>{{item.group_name}}</span><img style="margin-left:0;float:right" class="Img_ti" src="../../assets/img/del_p.png" alt="">
                    </div>
                  </div>
                  <!-- 添加规格值 -->
                  <div class="Rig_D ">
                    <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格值</div>
                    <div  v-for="items in item.spec_items" :key="items">
                      <span class="Sku_zhi">{{items.spec_value}}</span>       
                    </div>
                    <div class="Sku_inpiua">
                      <el-input type="tel" v-model.trim ="spec_ad" placeholder="请输入规格值"  style="margin-top:-3px" ></el-input>
                      <span class="Sku_sppo" @click="Sku_ADDSpace(spec_ad, item ,index)">添加</span>
                    </div>
                  </div>                      
                </div>
              </div>
              <!-- 添加规格名 值 -->
              <div  v-if="Sku  == 4 || Sku_e  == 'ad'">
                <div class="Rig_D ">
                  <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格名</div>
                  <div class="Rtty Righhh">
                    <el-input type="tel" v-model="SPEC.spec_name" placeholder="请输入规格名称" style="width:30%"></el-input>
                  </div>
                </div>
                <div class="Rig_D " style="margin-top:15px">
                  <div class="Rig_Ddd" style="margin-top:7px;width:70px">规格值</div>
                  <div class="Rtty Righhh">
                    <el-input type="tel" v-model="SPEC.spec_value" placeholder="请输入规格值" style="width:30%"></el-input>
                  </div>
                </div>
                <div style="margin: 30px 10px;">
                  <el-button @click="Sku_ADD()" type="primary">确定</el-button>
                  <el-button @click="Sku_ADD(false)">取消</el-button>
                </div>
              </div>
              <div v-if="Sku == 3 " style="border-bottom:2px  dashed  #eee;"> </div> 
              <div v-if="Sku == 3 " style="margin-top:20px">
                <el-table
                  border
                  style="width: 100%;"  
                  :data="Spec_many"
                  :span-method="tableMerge"
                >
                  <el-table-column 
                    v-for="(item , index) in Spec_many_title"
                    :key="index"
                    :label="item.group_name"
                    align="center"
                  >
                    <template #default="scope">
                      <span>{{scope.row.rows[index].spec_value}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="tuan_price" label="活动价" align="center">
                    <template #default="scope">
                      <input type="number" v-model="scope.row.form.tuan_price" class="Price">
                    </template>
                  </el-table-column>
                  <el-table-column prop="goods_price" label="销售价" align="center">
                    <template #default="scope">
                      <input type="number" v-model="scope.row.form.goods_price" class="Price">
                    </template>
                  </el-table-column>
                  <el-table-column prop="line_price" label="划线价" align="center" >
                    <template #default="scope">
                      <input type="number" v-model="scope.row.form.line_price" class="Price">
                    </template>
                  </el-table-column>
                 </el-table>
              </div>
            </div>
          </div>
          <el-form-item class="EditNewFormItemTube" prop="Spec.goods_price" v-if="Sku == 0">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品销售价格</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Spec.goods_price" placeholder="请输入商品简称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Spec.line_price" v-if="Sku == 0">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品活动价</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Spec.line_price" placeholder="请输入商品简称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Spec.tuan_price" v-if="Sku == 0">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品划线价</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Spec.tuan_price" placeholder="请输入商品简称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.sale_type">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>销售类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleFormRef.Row.sale_type" placeholder="请选择销售类型" >
                <el-option v-for="(item,key) in StatisticsColumns.sale_type" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.goods_status">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品状态</div>
            <div class="EditNewFormItemIpt">
             <el-select v-model="ruleFormRef.Row.goods_status" placeholder="请选择商品状态">
                <el-option v-for="(item,key) in StatisticsColumns.goods_status" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube" prop="Row.is_sale">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>销售状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="ruleFormRef.Row.is_sale" placeholder="请选择销售状态" style="width:100%;" >
                <el-option v-for="(item,key) in StatisticsColumns.is_sale" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>实际销量</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Row.sales_actual" placeholder="请输入实际销量"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>虚拟销量</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Row.sales_initial" placeholder="请输入虚拟销量"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItemTube">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>商品权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Row.goods_sort" placeholder="请输入虚拟销量"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>分类显示</div>
              <div>
                <el-radio v-model="ruleFormRef.Row.cate_show" label="1" size="large">显示</el-radio>
                <el-radio v-model="ruleFormRef.Row.cate_show" label="0" size="large">隐藏</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>推荐状态</div>
              <div>
                <el-radio v-model="ruleFormRef.Row.tuijian_status" label="10" size="large">推荐</el-radio>
                <el-radio v-model="ruleFormRef.Row.tuijian_status" label="20" size="large">不推荐</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>热门状态</div>
              <div>
                <el-radio v-model="ruleFormRef.Row.hot_status" label="10" size="large">热门</el-radio>
                <el-radio v-model="ruleFormRef.Row.hot_status" label="20" size="large">不热门</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="Holiday">
              <div>发现页推荐</div>
              <div>
                <el-radio v-model="ruleFormRef.Row.tuijian_discover" label="10" size="large">顶部推荐</el-radio>
                <el-radio v-model="ruleFormRef.Row.tuijian_discover" label="20" size="large">底部推荐</el-radio>
                <el-radio v-model="ruleFormRef.Row.tuijian_discover" label="30" size="large">不推荐</el-radio>
              </div>
            </div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 1">
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">PC封面图</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-images"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">手机面图</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-wap_image"
                  required
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList2"
                  :on-success="handleSuccess2"
                  :on-exceed="handleExceed2"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">商品图标</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-icon_image"
                  required
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList3"
                  :on-success="handleSuccess3"
                  :on-exceed="handleExceed3"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">手机端服务介绍</div>
            <div class="EditNewFormItemIpt">
              <div id="demo1"></div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">电脑端服务介绍</div>
            <div class="EditNewFormItemIpt">
              <div id="demo2"></div>
            </div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 2">
          <el-form-item class="EditNewFormItem" prop="Discount.vip">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆会员折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.vip" placeholder="请输入魔豆会员折扣"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.partner">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆合伙人折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.partner" placeholder="请输入魔豆合伙人折扣"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.month">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>企业月卡折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.month" placeholder="请输入企业月卡折扣"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.season">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>企业季卡折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.season" placeholder="请输入企业季卡折扣"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>企业年卡折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.year" placeholder="请输入企业年卡折扣"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.commission">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>魔豆合伙人返佣(百分制)</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.commission" placeholder="请输入魔豆合伙人返佣(百分制)"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="Discount.code">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>推荐码折扣</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="ruleFormRef.Discount.code" placeholder="请输入推荐码折扣"></el-input>
            </div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 3">
          <el-form-item class="EditNewFormBtns">
            <div v-if="Question.length > 0">
              <div class="Rihjj">
                <span>标题</span>
                <span>超链接</span>
                <span>操作</span>
              </div>
              <div class="Rihjj" v-for="item in Question" :key="item" style="border:none">
                <span>{{item.question}}</span>
                <span>{{item.answer}}</span>
                <span class="Ruuol">
                  <span @click="Quent_del(index)">删除</span>
                  <span @click="Quent_modify( 1 , item)">修改</span>
                </span>
              </div>
            </div>
            <div class="FormBtnsQuery BtnsQuery" @click="Quent_A(1)"><i class="el-icon-plus"></i>创建</div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 4">
          <el-form-item class="EditNewFormBtns">
            <div class="SEO">
              <div class="mane">SEO标题</div>
              <div style="width: 35%;">
                <el-input type="tel" v-model="ruleFormRef.Row.seo_title" placeholder="请输入SEO标题" ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="SEO">
              <div class="mane">SEO关键字</div>
              <div style="width: 35%;">
                <el-input type="tel" v-model="ruleFormRef.Row.seo_keyword" placeholder="请输入SEO关键字" ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="SEO">
              <div class="mane">SEO描述</div>
              <div style="width: 35%;">
                <el-input type="textarea" v-model="ruleFormRef.Row.seo_description" placeholder="请输入SEO描述"></el-input>
              </div>
            </div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 5">
          <el-form-item class="EditNewFormBtns">
            <div v-if="Related.length > 0">
              <div class="Rihjj">
                <span style="width:300px">关联商品</span>
                <span >商品别名</span>
                <span >操作</span>
              </div>
              <div class="Rihjj" v-for="item in Related" :key="item" style="border:none">
                <span style="width:300px">{{item.goods_id}}</span>
                <span style="width:300px">{{item.goods_name}}</span>
                <span class="Ruuol" style="float:right;width:120px">
                  <span @click="Related_del(index)">删除</span>
                  <span @click="Related_modify( 1 , item)">修改</span> 
                </span>
              </div>
            </div>
            <div class="FormBtnsQuery BtnsQuery" @click="Related_A(1)"><i class="el-icon-plus"></i>创建</div>
          </el-form-item>
        </div>
        <div class="EditNewForm" v-show="info_Show == 6">
          <el-form-item >
            <div v-if="Info.length > 0">
              <div class="Rihjj">
                <span>信息</span>
                <span>内容</span>
                <span>操作</span>
              </div>
              <div class="Rihjj" v-for="(item, index) in Info" :key="index"  style="border:none">
                <span>{{item.title}}</span>
                <span>{{item.info}}</span>
                <span class="Ruuol">
                  <span @click="Info_del(index)">删除</span>
                  <span @click="Info_modify( 1 , item)">修改</span>
                </span>
              </div>
            </div>
            <div class="FormBtnsQuery BtnsQuery" @click="Info_A(1)"><i class="el-icon-plus"></i> 创建</div>
          </el-form-item>
        </div>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="resetForm" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="!resetForm" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
  <!--常见问题-->
  <el-dialog v-model="dialogVisible" title="添加问题" width="25%" :before-close="handleClose">
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">标题</div>
      <div class="EditNewFormItemIpt">
        <el-input v-model="Question2.question" placeholder="请输入标题"></el-input>
      </div>
    </div>
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">超链接</div>
      <div class="EditNewFormItemIpt">
        <el-input v-model="Question2.answer" placeholder="请输入标题"></el-input>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="Quent_A(2)" v-if="dialogStatus3 === 5 ">确认</el-button>
        <el-button type="primary" @click="Quent_modify(2)" v-if="dialogStatus3 === 6 ">确认</el-button>
      </span>
    </template>
  </el-dialog>

  <!--关联商品-->
  <el-dialog v-model="dialogVisible2" title="添加商品" width="25%" :before-close="handleClose">
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">关联商品</div>
      <div class="EditNewFormItemIpt">
        <el-select v-model="Related2.goods_id" placeholder="请选择类型">
          <el-option v-for="item in Ordinary_list" :key="item.goods_id" :label="item.goods_name" :value="item.goods_id"></el-option>
        </el-select>
      </div>
    </div>
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">商品别名</div>
      <div class="EditNewFormItemIpt">
        <el-input type="tel" v-model="Related2.goods_name" placeholder="请输入销售价格"></el-input>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取消</el-button>
        <el-button type="primary" @click="Related_A(2)" v-if="dialogStatus3 === 3 ">确认</el-button>
        <el-button type="primary" @click="Related_modify(2)" v-if="dialogStatus3 === 4 ">确认</el-button>
      </span>
    </template>
  </el-dialog>

  <!--添加信息-->
  <el-dialog v-model="dialogVisible3" title="添加信息" width="25%" :before-close="handleClose">
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">信息</div>
      <div class="EditNewFormItemIpt">
        <el-input type="tel" v-model="Info2.title" placeholder="请输入信息"></el-input>
      </div>
    </div>
    <div class="EditNewFormBtns HHHH">
      <div class="EditNewFormItemName">内容</div>
      <div class="EditNewFormItemIpt">
        <el-input type="tel" v-model="Info2.info" placeholder="请输入内容"></el-input>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取消</el-button>
        <el-button type="primary" @click="Info_A(2)" v-if="dialogStatus3 === 1 ">确认</el-button>
        <el-button type="primary" @click="Info_modify(2)" v-if="dialogStatus3=== 2 ">确认</el-button>
      </span>
    </template>
  </el-dialog>


</template>
<script>

import axios from 'axios'
import wangEditor from 'wangeditor'
export default {
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      editId: 0, //删除id
      tableData:[],//列表数据
      StatisticsColumns:[],  //获取统计列表
      CateList:[],     //商品分类
      LabelList:[],    //商品标签
      Ordinary_list:[],   //全部商品
      Question:[],      //问题
      Related:[],      //关联商品
      Info:[],     //信息
      fileList:[],  //pc图片
      fileList2:[],  //手机端图片
      fileList3:[],  //商品图标
      SiteList:[], //站点
      Spec_many:[],  //多规格表格数据
      Spec_many_title:[],  //多规格表头数据
      count_map :{},
      specdata:[],
      acndex:1,  //弹出层选择
      ruleForm2: false,
      resetForm:'',
      total: 0,  //分页
      textMap:'',  //新增编辑
      dialogVisible:false,  // 弹出层控制
      dialogVisible2:false,
      dialogVisible3:false,
      dialogStatus3:'',
      editorData:'',   //富文本1
      editorData2:'',  //富文本2
      editor:'',
      editor2:'',
      wangE:0,
      spec_ad:'',  //添加规格值
      Sku:0,       // 规格显示隐藏
      Sku_e:null,  // 规格显示隐藏
      Sku_d:0,  // 规格列表显示隐藏
      info_Show:0, // 控制编辑新增
      titleMath:'',
      Row_piii2:'',     //商品分类选择
      // 列表搜索
      TypeList: [{id:10, name:'单规格'},{id:20, name:'多规格'}],
      Goods_staus: [{id:10, name:'上架'},{id:20, name:'下架'}],
      Question2:{question:'',answer:''},
      Related2:{goods_id:null,goods_name:''},
      Info2:{title:'',info:''},
      //搜索
      search: {   
        category_id: "",
        site_id: "",
        goods_name: "",
        spec_type: "",
        goods_status: "",
        keyword: "",
      },
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      SPEC:{
        spec_name:'',
        spec_value:''
      },
      rules:{
        Row:{
          Row_piii: [{ required: true, message: '请选择', trigger: 'change' }],
          goods_short_name: [{ required: true, message: '请选择', trigger: 'blur' }],
          goods_name: [{ required: true, message: '请选择', trigger: 'blur' }],
          goods_intro: [{ required: true, message: '请选择', trigger: 'blur' }],
          label_ids: [{ required: true, message: '请选择', trigger: 'change' }],
          site_ids: [{ required: true, message: '请选择', trigger: 'change' }],
          spec_type: [{ required: true, message: '请选择', trigger: 'change' }],
          is_sale: [{ required: true, message: '请选择', trigger: 'change' }],
          goods_status: [{ required: true, message: '请选择', trigger: 'change' }],
          sale_type: [{ required: true, message: '请选择', trigger: 'change' }],
        },
        Spec:{
          goods_price: [{ required: true, message: '请选择', trigger: 'blur' }],
          line_price: [{ required: true, message: '请选择', trigger: 'blur' }],
          tuan_price: [{ required: true, message: '请选择', trigger: 'blur' }],
        },
        Discount:{
          vip: [{  required: true, message: '请输入', trigger: 'blur' }],
          partner: [{  required: true, message: '请输入', trigger: 'blur' }],
          month: [{  required: true, message: '请输入', trigger: 'blur' }],
          season: [{  required: true, message: '请输入', trigger: 'blur' }],
          year: [{  required: true, message: '请输入', trigger: 'blur' }],
          commission: [{  required: true, message: '请输入', trigger: 'blur' }],
          code: [{  required: true, message: '请输入', trigger: 'blur' }],
        },
      },
      ruleFormRef:{
        Row: {
          Row_piii:'',     //商品分类选择
          category_id:'', //商品分类ID
          goods_name: "", //商品名称
          goods_short_name: "", //商品简称
          goods_intro: "", //简介
          site_ids: [], //所属站点（多选）
          label_ids: [], //标签（多选）
          spec_type: '',  //商品规格:10=单规格,20=多规格
          sale_type: '',  //销售类型:10=立即购买,20=预定,30=报名,40=咨询
          is_sale: '',  //是否在售:0=在售,1=已售完
          sales_initial:'',   //虚拟销量
          sales_actual: '',  //权重
          goods_status: '',  //商品状态:10=上架,20=下架
          goods_sort: '',  //实际销量
          cate_show: '',  //分类显示:0=隐藏,1=显示
          tuijian_status: '',  //推荐状态:10=推荐,20=不推荐
          hot_status: '',  //热门状态:10=热门,20=不热门
          tuijian_discover: '',  //推荐状态:10=顶部推荐,20=底部推荐,30=不推荐
          images: "", //商品图片
          wap_image: "", //手机端图片
          icon_image: "", //商品图标
          content: "", //手机端服务介绍
          content_pc: "", //电脑端服务介绍
          seo_title: "", //SEO标题
          seo_keyword: "", //SEO关键字
          seo_description: "" ,//SEO描述
        },
        Spec:{ //单规格价格
          goods_price: '', //销售价
          line_price: '', //划线价
          tuan_price: '' //优惠价
        },
        Discount:{ //折扣
          vip:'', 
          partner: '', 
          month: '',
          season: '', 
          year: '',
          commission: '', 
          code: ''
        },
      },
      form:{ //多规格价格
        goods_price:null, //商品销售价
        line_price:null  //商品划线价
      },
      
    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
    })   
    axios.get('/admin/goods/goods/statisticsColumns',{   // 销售列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StatisticsColumns = res.data.data
        }
    })   
    axios({ method: 'post', url: '/admin/ajax/cateList',   //搜索列表
    }).then(function (res) {
        if (res.code == 401) {
          that.$router.push('/Login');
        }else {
          that.CateList = res.data.data
          console.log(that.CateList );
        }
    }) 
    axios({
      method: 'post',
      url: '/admin/ajax/labelList',       //商品标签
    }).then(function (res) {
      if (res.code == 401) {
        that.$router.push('/Login');
      }else {
        that.LabelList = res.data.data.list
        that.total2 = res.data.data.total
      }
    }) 
    axios({                   //  关联
      method: 'post',
      url: '/admin/goods/goods/index',    
    }).then(function (res) {
      if (res.code == 401) {
        that.$router.push('/Login');
      }else {
        that.Ordinary_list = res.data.data.rows
      }
    })  
  },

  methods: {
    //获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'POST',
        url: '/admin/goods/goods/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //新增编辑选项控制
    handleSelect2(key) {
      if (key == 1) {
        this.info_Show = 0;
      } else if(key == 2){
        this.info_Show = 1
        if (this.wangE == 20) {
            this.editor.destroy();
          } else {
            this.wangE = 0
          }
          this.init()
          this.init2()
      } else if(key == 3){
        this.info_Show = 2
      } else if(key == 4){
        this.info_Show = 3
      } else if(key == 5){
        this.info_Show = 4
      } else if(key == 6){
        this.info_Show = 5
      } else if(key == 7){
        this.info_Show = 6
      }
    },
    // 获取编辑详情
    HandleEdit(e, data) {                       
      var that = this
      that.ruleForm2 = true;
      that.resetForm = false;
      that.titleMath = '编辑'
      that.info_Show = 0
      that.editId = data.goods_id
      axios.get('/admin/goods/goods/read', {  
        params: {
          id: data.goods_id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.ruleFormRef.Row.Row_piii = res.data.data.row.category_id
          that.ruleFormRef.Row.goods_name = res.data.data.row.goods_name
          that.ruleFormRef.Row.goods_short_name = res.data.data.row.goods_short_name
          that.ruleFormRef.Row.goods_intro = res.data.data.row.goods_intro
          that.ruleFormRef.Row.site_ids = res.data.data.row.site_ids
          that.ruleFormRef.Row.label_ids = res.data.data.row.label_ids
          that.ruleFormRef.Row.spec_type = res.data.data.row.spec_type
          that.ruleFormRef.Row.sale_type = res.data.data.row.sale_type
          that.ruleFormRef.Row.is_sale = parseInt(res.data.data.row.is_sale)
          that.ruleFormRef.Row.sales_initial = res.data.data.row.sales_initial
          that.ruleFormRef.Row.sales_actual = res.data.data.row.sales_actual
          that.ruleFormRef.Row.goods_status = res.data.data.row.goods_status
          that.ruleFormRef.Row.goods_sort = res.data.data.row.goods_sort
          that.ruleFormRef.Row.cate_show = res.data.data.row.cate_show
          that.ruleFormRef.Row.tuijian_status = res.data.data.row.tuijian_status
          that.ruleFormRef.Row.hot_status = res.data.data.row.hot_status
          that.ruleFormRef.Row.tuijian_discover = res.data.data.row.tuijian_discover
          that.ruleFormRef.Row.images = res.data.data.row.full_images
          that.ruleFormRef.Row.wap_image = res.data.data.row.full_wap_image
          that.ruleFormRef.Row.icon_image = res.data.data.row.full_icon_image
          // 单规格价格
          that.ruleFormRef.Spec = res.data.data.spec[0]
          // 手机端  pc端
          that.editorData = res.data.data.row.content
          that.editorData2 = res.data.data.row.content_pc 
          //多规格表格列表
          that.Spec_many = res.data.data.specData.spec_list || [];
          that.Spec_many_title = res.data.data.specData.spec_attr || [];
          //折扣
          that.ruleFormRef.Discount = res.data.data.discount
          //SEO
          that.ruleFormRef.Row.seo_title = res.data.data.row.seo_title
          that.ruleFormRef.Row.seo_keyword = res.data.data.row.seo_keyword
          that.ruleFormRef.Row.seo_description = res.data.data.row.seo_description
          //问题
          that.Question = res.data.data.question
          //关联
          var krr = []
          for (let index = 0; index < res.data.data.related.length; index++) {
            krr[index] = {
              goods_id : res.data.data.related[index].related_goods_id,
              goods_name : res.data.data.related[index].related_goods_name
            }
          }
          that.Related = krr
          //信息
          that.Info = res.data.data.info
           //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_images;
          fileArr.push(fileObj);
          that.fileList = fileArr
           //图片
          let fileArr2 = [];
          let fileObj2 = {};
          fileObj2.name = "file";
          fileObj2.url = res.data.data.row.full_wap_image;
          fileArr2.push(fileObj2);
          that.fileList2 = fileArr2
           //图片
          let fileArr3 = [];
          let fileObj3 = {};
          fileObj3.name = "file";
          fileObj3.url = res.data.data.row.full_icon_image;
          fileArr3.push(fileObj3);
          that.fileList3 = fileArr3
          if(that.Spec_many != '' || that.Spec_many_title != ''){
            that.genSpecList()
            that.Sku = 3
          }
        }
      })
    },
    //编辑
    BtnEdit() {
      console.log(123456);
      var that = this
      let arr = {}
      let spec_attr = that.Spec_many_title
      let spec_list = that.Spec_many
      arr.spec_attr  = spec_attr
      arr.spec_list  = spec_list
      that.$refs['ruleFormRef'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'put',
            url: '/admin/goods/goods/edit',
            data: {
              id: that.editId,
              row: {
                category_id  : that.ruleFormRef.Row.Row_piii,
                goods_name : that.ruleFormRef.Row.goods_name ,
                goods_short_name : that.ruleFormRef.Row.goods_short_name ,
                goods_intro : that.ruleFormRef.Row.goods_intro ,
                site_ids : that.ruleFormRef.Row.site_ids ,
                label_ids : that.ruleFormRef.Row.label_ids ,
                spec_type : that.ruleFormRef.Row.spec_type ,
                sale_type : that.ruleFormRef.Row.sale_type ,
                is_sale : that.ruleFormRef.Row.is_sale ,
                sales_initial : that.ruleFormRef.Row.sales_initial ,
                sales_actual : that.ruleFormRef.Row.sales_actual ,
                goods_status : that.ruleFormRef.Row.goods_status ,
                goods_sort : that.ruleFormRef.Row.goods_sort ,
                cate_show : that.ruleFormRef.Row.cate_show ,
                tuijian_status : that.ruleFormRef.Row.tuijian_status ,
                hot_status : that.ruleFormRef.Row.hot_status ,
                tuijian_discover : that.ruleFormRef.Row.tuijian_discover ,
                images : that.ruleFormRef.Row.images ,
                wap_image : that.ruleFormRef.Row.wap_image ,
                icon_image : that.ruleFormRef.Row.icon_image ,
                content : that.editorData ,
                content_pc : that.editorData2 ,
                seo_title : that.ruleFormRef.Row.seo_title ,
                seo_keyword : that.ruleFormRef.Row.seo_keyword ,
                seo_description  : that.ruleFormRef.Row.seo_description,
              },
              spec:that.ruleFormRef.Spec,
              spec_many:arr,
              question:that.Question,
              related:that.Related,
              info:that.Info,
              discount:that.ruleFormRef.Discount
            },
          }).then(function (res) {
            that.$message.success(res.data.msg);
            that.ruleForm2 = false
            that.loadTable()
          })
        }
      })
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      arr.push(data.goods_id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
          axios({
          method: 'DELETE',
          url: '/admin/goods/goods/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    // 分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page
      that.loadTable();
    },
    //关闭按钮
    Close() {
      var that = this
      that.ruleForm2 = false;
      that.Spec_many_title = ''
      that.Spec_many = ''
      that.Sku = 0
    },
    //商品分类
    handleChange() {      
      var that = this
        var num = Number(that.ruleFormRef.Row.Row_piii.slice(-1))
        that.ruleFormRef.Row.category_id = num
    },
    //搜索商品分类
    handleChange2() {       
      var that = this
        var num = Number(that.Row_piii2.slice(-1))
        that.search.category_id = num
    },
    Info_A(e) {  //信息创建
      var that = this
      if (e == 1) {
        that.dialogStatus3 = 1
        that.textMap = '新增'
        that.dialogVisible3 = true
        that.Info2 = {title:'',info:''}
      } else if (e == 2) {
        that.Info.push(that.Info2)
        that.dialogVisible3 = false
      }
    },
    Info_del(id){   //信息删除
      var that = this
      let index = that.Info.findIndex(obj => obj.index  === id)
      that.Info.splice(index, 1)
    },
    Info_modify(e ,data){  //信息修改
      var that = this
      if (e == 1) {
        that.dialogStatus3 = 2
        that.textMap = '修改'
        that.dialogVisible3 = true
        that.Info2 = data
      } else if (e == 2) {
        that.Info.Info2 = data
        that.dialogVisible3 = false
      }
    },
    Related_A(e) {   //关联创建
      var that = this
      if (e == 1) {
        that.dialogStatus3 = 3
        that.textMap = '新增'
        that.dialogVisible2 = true
        that.Related2 = {goods_name:'',goods_id:''}
      } else if(e == 2) {
        that.Related.push(that.Related2)
        that.dialogVisible2 = false
        console.log(that.Related);
      }
    },
    Related_modify(e,data){ //关联修改
      var that = this
      if (e == 1) {
        that.dialogStatus3 = 4
        that.textMap = '修改'
        that.dialogVisible2 = true
        that.Related2 = data
      } else if (e == 2) {
        that.Related.Related2 = data
        that.dialogVisible2 = false
      }
    },
    Related_del(id){ //关联删除
      var that = this 
      let index = that.Related.findIndex(obj => obj.index  === id)
      that.Related.splice(index, 1)
    },
    Quent_A(e) {     //问题新建
      var that = this
      console.log(135);
      if (e == 1) {
        that.dialogStatus3 = 5
        that.textMap = '新增'
        that.dialogVisible = true
        that.Question2 = {}
      } else if (e == 2) {
        that.Question.push(that.Question2)
        that.dialogVisible = false
      }
    },
    Quent_modify(e, data){ //问题修改
      var that = this
      if (e == 1) {
        that.dialogStatus3 = 6
        that.textMap = '修改'
        that.dialogVisible = true
        that.Question2 = data
      } else if (e == 2) {
        that.Question.Question2 = data
        that.dialogVisible = false
      }
    },
    Quent_del(id){ //问题删除
      var that = this 
      let index = that.Question.findIndex(obj => obj.index  === id)
      that.Question.splice(index, 1)
    },
    //重置刷新
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          category_id: "",
          site_id: "",
          goods_name: "",
          spec_type: "",
          goods_status: "",
          keyword: "",
        };
        that.Row_piii2=''
      }
      that.loadTable()
    },
    //打开创建按钮
    showAdd() {
      var that = this
      that.ruleForm2 = true;
      that.resetForm = true;
      that.titleMath = '添加'
      that.info_Show = 0
      that.ruleFormRef.Row = {
        Row_piii:'',
        category_id: 0, //商品分类ID
        goods_name: "", //商品名称
        goods_short_name: "", //商品简称
        goods_intro: "", //简介
        site_ids: [], //所属站点（多选）
        label_ids: [], //标签（多选）
        spec_type: '',  //商品规格:10=单规格,20=多规格
        sale_type: '',  //销售类型:10=立即购买,20=预定,30=报名,40=咨询
        is_sale: '',  //是否在售:0=在售,1=已售完
        sales_initial:'',   //虚拟销量
        sales_actual: '',  //权重
        goods_status: '',  //商品状态:10=上架,20=下架
        goods_sort: '',  //实际销量
        cate_show: '1',  //分类显示:0=隐藏,1=显示
        tuijian_status: '10',  //推荐状态:10=推荐,20=不推荐
        hot_status: '10',  //热门状态:10=热门,20=不热门
        tuijian_discover: '10',  //推荐状态:10=顶部推荐,20=底部推荐,30=不推荐
        images: "", //商品图片
        wap_image: "", //手机端图片
        icon_image: "", //商品图标
        content: "", //手机端服务介绍
        content_pc: "", //电脑端服务介绍
        seo_title: "", //SEO标题
        seo_keyword: "", //SEO关键字
        seo_description: "" //SEO描述
      };
      that.Question = [],                       //问题
      that.Related = [],                       //关联商品
      that.Info = [],                          //信息
      that.ruleFormRef.Spec = {
        goods_price:null, //销售价
        line_price: null, //划线价
        tuan_price: null//优惠价
      }
      that.ruleFormRef.Discount = {
        vip:'', 
        partner: '', 
        month: '',
        season: '', 
        year: '',
        commission: '', 
        code: ''
      },
      that.fileList = [];
      that.fileList2 = [];
      that.fileList3 = [];
    },  
    //创建
    BtnAdd() {
      var that = this 
      let arr = {}
      that.ruleFormRef.Row.category_id = that.ruleFormRef.Row.Row_piii[1]
      let spec_attr = that.Spec_many_title
      let spec_list = that.Spec_many
      arr.spec_attr  = spec_attr
      arr.spec_list  = spec_list
      that.$refs['ruleFormRef'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/goods/goods/add',
            data: {
              row: {
                category_id  : that.ruleFormRef.Row.category_id ,
                goods_name : that.ruleFormRef.Row.goods_name ,
                goods_short_name : that.ruleFormRef.Row.goods_short_name ,
                goods_intro : that.ruleFormRef.Row.goods_intro ,
                site_ids : that.ruleFormRef.Row.site_ids ,
                label_ids : that.ruleFormRef.Row.label_ids ,
                spec_type : that.ruleFormRef.Row.spec_type ,
                sale_type : that.ruleFormRef.Row.sale_type ,
                is_sale : that.ruleFormRef.Row.is_sale ,
                sales_initial : that.ruleFormRef.Row.sales_initial ,
                sales_actual : that.ruleFormRef.Row.sales_actual ,
                goods_status : that.ruleFormRef.Row.goods_status ,
                goods_sort : that.ruleFormRef.Row.goods_sort ,
                cate_show : that.ruleFormRef.Row.cate_show ,
                tuijian_status : that.ruleFormRef.Row.tuijian_status ,
                hot_status : that.ruleFormRef.Row.hot_status ,
                tuijian_discover : that.ruleFormRef.Row.tuijian_discover ,
                images : that.ruleFormRef.Row.images ,
                wap_image : that.ruleFormRef.Row.wap_image ,
                icon_image : that.ruleFormRef.Row.icon_image ,
                content : that.editorData ,
                content_pc : that.editorData2 ,
                seo_title : that.ruleFormRef.Row.seo_title ,
                seo_keyword : that.ruleFormRef.Row.seo_keyword ,
                seo_description  : that.ruleFormRef.Row.seo_description,
              },
              spec:that.ruleFormRef.Spec,
              spec_many: arr,
              question:that.Question,
              related:that.Related,
              info:that.Info,
              discount:that.ruleFormRef.Discount
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleForm2 = false
              that.loadTable()
            }
          }) 
        }
      })
    },
    //选规格 1
    Sku_Chang(e) {
      var that = this
      if (e == 10) {  
        that.Sku = 0
      } else if(e == 20) {
        that.Sku = 1
      } else if (e == 'add') {
        if (that.SPEC.spec_value != '') {
          that.Sku_e = 'ad'
        } else {
          that.Sku = 4
        }
        that.SPEC.spec_name = ''
        that.SPEC.spec_value = ''
      } else if (e == 'sure') {
        that.Sku = 3
      }
    },
    //添加规格
    Sku_ADD(e) {    
      var that = this
      if (e == false) {
        that.Sku_e = ''
      }else {
        axios({ 
          method: 'post',
          url: '/admin/goods/goods/addSpec',    
          data: {
            spec_name: that.SPEC.spec_name,
            spec_value:that.SPEC.spec_value
          },
        }).then(function (res) {
          that.Sku = 3
          that.Sku_e = ''
          that.Spec_many_title.push({
            group_name: that.SPEC.spec_name,
            group_id: res.data.data.spec_id,
            spec_items: [{
              spec_value: that.SPEC.spec_value,
              item_id: res.data.data.spec_value_id,
            }]
          });
          that.Spec_many = that.genSpecList()
          console.log( that.Spec_many);
          console.log( that.Spec_many_title);

        }) 
      }
 
    },
    //添加规格值
    Sku_ADDSpace(e,data) {  
      var that = this
      var specId = data.group_id
      if(e!= ''){
        axios({
          method: 'post',
          url: '/admin/goods/goods/addSpecValue',    
          data: {
            spec_id: specId,
            spec_value:e
          },
        }).then(function (res) {
          var spec_value_id = res.data.data.spec_value_id
          let arr = { item_id : spec_value_id,  spec_value : e }
          let currSku = that.Spec_many_title.find( item => item.group_id == specId)
          currSku.spec_items.push(arr)
          console.log(currSku);
          that.Spec_many = that.genSpecList()
          console.log(that.Spec_many);
          that.spec_ad = ''
        }) 
      }
    },
    //删除规格
    imgClick(id){
      var that = this
      let index = that.Spec_many_title.findIndex(obj => obj.index  === id)
      that.Spec_many_title.splice(index, 1)
      if(that.Spec_many_title.length == 0){
        that.Sku = 0
      }
      that.Spec_many = that.genSpecList()
    },
    // 合并行
    tableMerge({ row, column, rowIndex, columnIndex }){
      let tempIndex = columnIndex;
      let rows = row.rows;
      if(tempIndex < rows.length-1){
        let ids = rows.filter((item,index) => index <= tempIndex).map(( item ,index) => {
          return item.item_id;
        }).join("_")
        let spans = this.count_map[ids];
        //同层变量
        let stepArr = Object.keys(this.count_map).filter( item => item.length == ids.length);
        stepArr.sort( (a,b) => a>b ? 1 : -1)
        let currIndex = stepArr.findIndex( item => item == ids)
        let min = 0;
        if(currIndex != 0){
          min = stepArr.slice(0,currIndex).reduce( (total,item,index) => {
            return total + ( item==ids ? 0 : this.count_map[ids])
          },0)
        }
        if(min == rowIndex){
          return [spans,1]
        }else{
          return [0,0];
        }
      }else{
        return [1,1]
      }
    },
    // 递归
    genSpecList(){
			let result = [];
			let rows = this.Spec_many_title[0];
			rows.spec_items.forEach( item => {
				let src = {
					spec_sku_id : item.item_id + "",
					rows : [{
						item_id : item.item_id,
						spec_value : item.spec_value
					}],
					form : {
						tuan_price : '',
						line_price : '',
					}
				};
				let tempArr = this.infiniteAssign(src);
				this.count_map[item.item_id + ""] = tempArr.length;
				result.push(...tempArr);

			})
			return result;
		},
    infiniteAssign(src,index = 1){
			let result = [];
			let rows = this.Spec_many_title[index];
			if(!rows){
				return [src];
			}
			let spec_items = rows.spec_items;
			spec_items.forEach( item => {
				let copeSrc = JSON.parse(JSON.stringify(src));
				let arr = copeSrc.spec_sku_id.split("_");
				arr.push(item.item_id);
				copeSrc.spec_sku_id = arr.join("_");
				copeSrc.rows.push({
					item_id : item.item_id,
					spec_value : item.spec_value
				})
				let tempArr = this.infiniteAssign(copeSrc,index+1);
				this.count_map[copeSrc.spec_sku_id] = tempArr.length;
				result.push(...tempArr);
			})
			return result;

		},
    init() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr
        const editor = new wangEditor(`#demo1`)
        Eat = editor
        that.wangE = 20
        // 配置 onchange 回调函数，将数据同步到 vue 中
        // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
          token:that.heads.token
        }
        editor.config.onchange = (newHtml) => {
          that.editorData = newHtml
        }
        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            // console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            // console.log(result, 'success')
          }
        }
        editor.create()
        // 创建编辑器
        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
        // console.log(that.editor)
      },200)
    }, 
    init2() {
      var that = this
      setTimeout(function() {
        var Eat = that.Eattr
        const editor = new wangEditor(`#demo2`) 
        Eat = editor 
        that.wangE = 20 
        // console.log(that.wangE)
        // console.log(Eat)
        // 配置 onchange 回调函数，将数据同步到 vue 中
        // 下面两个配置，使用其中一个即可显示“上传图片”的tab。但是两者不要同时使用！！！
        editor.config.uploadImgHeaders = {
            token:that.heads.token
        }
        
        editor.config.onchange = (newHtml) => {
          // console.log(newHtml)
          that.editorData2 = newHtml
        }

        // editor.config.uploadImgShowBase64 = true   // 使用 base64 保存图片
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          // 图片上传并返回结果，但图片插入错误时触发
          fail: function (xhr, editor, result) {
            // console.log(result)
          },
          success: function (xhr, editor, result) {
            // 图片上传并返回结果，图片插入成功之后触发
            // console.log(result, 'success')
          }
        }

        editor.create()
        // 创建编辑器

        editor.txt.html(that.editorData2) // 重新设置编辑器内容
        that.editor = editor
        // console.log(that.editor)
      },200)
    }, 
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.ruleFormRef.Row.images = e.data.url
    },
    handleSuccess2(e) {
      var that = this
      that.ruleFormRef.Row.wap_image = e.data.url
    },
    handleSuccess3(e) {
      var that = this
      that.ruleFormRef.Row.icon_image = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-images"].clearFiles()
      this.$refs["upload-images"].handleStart(files[0])
      this.$refs["upload-images"].submit();
    },
    handleExceed2  (files)  {
      this.$refs["upload-wap_image"].clearFiles()
      this.$refs["upload-wap_image"].handleStart(files[0])
      this.$refs["upload-wap_image"].submit();
    },
    handleExceed3  (files)  {
      this.$refs["upload-icon_image"].clearFiles()
      this.$refs["upload-icon_image"].handleStart(files[0])
      this.$refs["upload-icon_image"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    //图片上传成功
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  },
}
</script>

<style scoped>
.Ordinary_i /deep/ .el-dialog__body {
  padding-right: 0 !important;
}
.Ordinary_i .tttyt{
  margin-bottom: 20px;
}
.Ordinary_i .Rig_D{
  margin-bottom: 0px;
}
.Holiday{
  display: flex;
}
.Holiday div:nth-child(1){
  margin-right: 30px;
}
.BtnsQuery{
  float: left;
}
.SEO{
  display: flex;
  align-items: center;
}
.mane{
  width: 110px;
  margin-right: 30px;
}
.EditNewFormBtns .EditNewFormItemIpt{
  width: 97%;
}
.HHHH{
  margin-bottom: 15px;
}
</style>